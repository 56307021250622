<template>
    <section class="container-fluid obras-ver-documentacion custom-scroll overflow-auto">
        <titulo-divisor titulo="Carpetas" class="mx-0">
            <div class="row">
                <div class="col-auto  pl-1">
                    <button class="btn btn-general f-12 px-3" @click="crearCarpeta">Crear carpeta</button>
                </div>
            </div>
        </titulo-divisor>
        <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="br-4 border">
                    <p class="text-general f-14 f-600 text-center py-3 border-bottom-g mb-2 shadow29">Carpetas</p>
                    <div class="listado-carpetas px-2 pb-3 overflow-auto custom-scroll mr-1 mb-2">
                        <card-folder v-for="data in foldersList" :key="data.id" :class="`${idFolderSelected == data.id ? 'selected' : ''}`"
                        :folderName="data.nombre"
                        :cantFiles="data.cantidad_archivos"
                        @editFolder="editFolder(data)"
                        @deleteFolder="deleteFolder(data.id)"
                        @action="loadFolderFiles(data.id)"
                        />
                    </div>
                </div>    
            </div>
            <div class="col-lg-8 mb-3 mb-lg-0">
                <div class="br-4 border">
                    <div class="row border-bottom-g f-600 f-14 mx-0 mb-2 shadow29">
                        <div class="col-md-3 col-lg-3 my-auto text-general">
                            <p class="py-3">Archivos</p>
                        </div>
                        <div class="col-md-3 col-lg-1 my-auto text-center text-general">
                            <p class="py-3">Ver</p>
                        </div>
                        <div class="col-md-3 col-lg-3 my-auto text-general">
                            <p class="py-3">Responsable</p>
                        </div>
                        <div class="col-md-4 col-lg-3 my-auto text-general">
                            <p class="">Fecha de creación</p>
                        </div>
                        <div class="col-md-2 col-lg-2 my-auto text-center icon-option">
                            <el-tooltip content="Subir archivo" effect="light" placement="right" visible-arrow>
                                <i v-if="idFolderSelected != null" class="icon-plus-box f-20 cr-pointer" @click="agregarArchivo" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="listado-archivos px-2 pb-3 overflow-auto custom-scroll mr-1 mb-2 ">
                        <card-folder-file v-for="data in selectedFolderFiles" :key="data.id"
                        :fileName="data.nombre"
                        :file="data.archivo"
                        :fileType="tipoArchivo(data.archivo)"
                        :idTipo="data.id_tipo"
                        :visualizar="data.visualizador_archivo"
                        :userName="data.user.nombre"
                        :userImg="data.user.foto"
                        :dateCreated="fechaCreacionArchivo(data.created_at)"
                        @dowloadFile="handleDowloadFile(data.archivo)"
                        @editFile="handleEditFile(data)"
                        @deleteFile="deleteFileOpen(data.id)"
                        />
                        <p v-if="idFolderSelected == null" class="text-center f-20 f-600 pt-3">Por favor seleccionar una carpeta</p>
                    </div>
                </div>                
            </div>
        </div>
        <!-- Partials -->
        <modal-agregar-carpeta  ref="abrirAgregarCarpeta" />
        <modal-eliminar ref="abrirEliminarCarpeta" title="Eliminar carpeta" mensaje="¿Está seguro que quiere eliminar esta carpeta?" @delete="handleDeleteFolder" />
        <modal-eliminar ref="abrirEliminarArchivo" title="Eliminar archivo" mensaje="¿Está seguro que quiere eliminar este archivo?" @delete="handleDeleteFolderFile" />
        <modal-adjuntos-general ref="abrirAgregarArchivoCarpeta" @saveFile="handleUploadFile" view-file/>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
    components: { 
        modalAgregarCarpeta: () => import('../partials/modalAgregarCarpeta'),
    },
    data(){
        return{
            visibleEditarArchivo: false,
            inputNombreArchivo: '',
            selectedFolderFiles: [],
            idFolderSelected:null,
            idProyecto: this.$route.params.id,
            fileToDelete: '',
            editandoArchivoUnico: false
        }
    },
    async mounted () {
        await this.listFolders()
    },
     computed: {
        ...mapGetters({
            foldersList: 'obras/gallery/foldersList',
            folderFilesList: 'obras/gallery/folderFilesList',
            key: 'files/files/key'
        }),
    },
    methods:{
        ...mapActions({
            Action_get_folders: 'obras/gallery/Action_get_folders',
            Action_delete_folder: 'obras/gallery/Action_delete_folder',
            Action_get_folders_files: 'obras/gallery/Action_get_folders_files',
            Action_delete_folder_file: 'obras/gallery/Action_delete_folder_file',
            uploadFile: 'files/files/Action_save_file_to_aws',
            Action_save_folder_file: 'obras/gallery/Action_save_folder_file',
            Action_edit_folder_file: 'obras/gallery/Action_edit_folder_file',
        }),
        // Folders
        async listFolders(){
            await this.Action_get_folders({
                id_obra:this.idProyecto,
                idTipo: 8,
            });
        },
        crearCarpeta(){
            this.$refs.abrirAgregarCarpeta.toggle('documentacion', this.idProyecto)
        },
        editFolder(data){
            this.$refs.abrirAgregarCarpeta.toggle('documentacion',this.idProyecto, data)
        },
        deleteFolder(id){
            this.idFolderSelected = id
            this.$refs.abrirEliminarCarpeta.toggle()
        },
        async handleDeleteFolder(){
            await this.Action_delete_folder({idProyecto: this.idProyecto,idCarpeta: this.idFolderSelected})
            this.listFolders()
        },

        // Archivos 
        async loadFolderFiles(id){
            this.idFolderSelected = id
            await this.Action_get_folders_files({ idProyecto: this.idProyecto, idCarpeta:id})
            this.selectedFolderFiles = this.folderFilesList.data
        },
        fechaCreacionArchivo(fecha){
            return moment(fecha).format('DD MMM YYYY')
        },
        tipoArchivo(archivo){
            let file = archivo.split('.')
            let ext = file[file.length - 1]
            return ext
        },
        agregarArchivo(){
            this.$refs.abrirAgregarArchivoCarpeta.toggle()
        },
        deleteFileOpen(id_file){
            this.fileToDelete = id_file
            this.$refs.abrirEliminarArchivo.toggle()
        },
        async handleEditFile(data){
            this.$refs.abrirAgregarArchivoCarpeta.toggle(data, true)
        },
        async handleDeleteFolderFile(){
            await this.Action_delete_folder_file({ 
                idProyecto: this.idProyecto,
                idCarpeta: this.idFolderSelected,
                idArchivo: this.fileToDelete
            })
            //await this.Action_get_folders_files({idProyecto: this.idGallery, idCarpeta: this.idFolderSelected})
            this.selectedFolderFiles = this.folderFilesList.data
            this.listFolders()
        },
        async handleUploadFile(data){

            let ruteFile = ''
            
            if(data[0].filesToUpload && data[0].filesToUpload.lastModified != 0) {
                await this.uploadFile({ 
                    file: data[0].filesToUpload,
                    path: '/obras/documentacion' 
                })
            } else if(data[0].filesToUpload){
                ruteFile = data[0].filesToUpload.route
            }
            

            let payload = {
                idProyecto: this.idProyecto, 
                idCarpeta: this.idFolderSelected,
                nombre: data[0].fileName,
                id_tipo: data[0].fileType,
                archivo: ruteFile.length ? ruteFile : this.key,
                id_obras_carpetas_archivos: data[0].idFile,
                visualizador_archivo: data[0].radioVisualizar
            } 
            
            if (data[1]) {
                await this.Action_edit_folder_file(payload)
            } else {
                await this.Action_save_folder_file(payload)
            }


            this.listFolders()
            this.loadFolderFiles(this.idFolderSelected)
            this.$refs.abrirAgregarArchivoCarpeta.toggle()
        },
        handleDowloadFile(){
            console.log('descargar archivo');
        },
        cambiarNombreArchivo(){
            this.visibleEditarArchivo = !this.visibleEditarArchivo
        },
    }
}
</script>

<style lang="scss" scoped>
.obras-ver-documentacion{
    height: calc(100vh - 385px);
    .listado-carpetas{
        //min-height: 400px;
        max-height: 400px;
        .carpeta{
            .cant-archivos{
                height: 16px;
            }
            &:hover{
                background: var(--color-general);
                color: #fff !important;
                cursor: pointer;
                .cant-archivos{
                    background: white;
                    color: var(--color-general) !important;
                }
            }
        }
    }
    .listado-archivos{
        //min-height: 400px;
        max-height: 400px;
        .archivo{
            &:hover{
                background-color: var(--color-f5);
            }
        }
    }
}
</style>